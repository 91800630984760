import Link from 'next/link';
import PropTypes from 'prop-types';

export default function HomeContact({ theme }) {
    return (
        <section className={`section has-background-dark`}>
            <div className="container has-text-centered">
                <h3
                    className={`title is-unselectable ${
                        theme === 'light' ? 'has-text-primary' : 'has-text-white'
                    } mb-6`}
                >
                    Want to talk?
                </h3>
                <hr />
                <div className="columns">
                    <div className="column is-8 is-offset-2">
                        <p>
                            <Link href="/contact">
                                <a className="button is-info is-medium">Say hi!</a>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

HomeContact.propTypes = {
    theme: PropTypes.string,
};

HomeContact.defaultProps = {
    theme: 'dark',
};
